@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Bangla:wght@100..800&display=swap");
@import "@fortawesome/fontawesome-free/css/all.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: var(--theme-opposite-color);
}

@font-face {
  font-family: "anek-bangla";
  src: url("./fonts/AnekBangla_SemiExpanded-SemiBold.ttf") format("truetype");
  /* font-weight: normal;
  font-style: normal; */
}

:root {
  --navbar-background-color: #363935;
  --background: #131316;

  --background-color: #000000;
  --theme-secondary2-color: #1b1b1b;
  --theme-secondary-color: #1f2026;
  --theme-color: #000000;
  --theme-opposite-color: #ffffff;
  --text-secondary-color: #565a5f;
  --accent-color: #c3fc49;
  --faded-white: #c2cdc2;
  /* --theme-secondary2-color: #1F2026; */
  /* 676C70 */
}

body,
html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}

html,
a,
span,
button,
p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-optical-sizing: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

/* :root {
   
} */

.horizontal-scroll::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for webkit browsers (Chrome, Safari, etc.) */
}

.row-alignment,
.row-column-alignment {
  display: flex;
  flex-direction: row;
}

.row-column-reverse-alignment {
  display: flex;
  flex-direction: row;
}

.column-alignment {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.fill {
  flex: 1 1 0;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-right {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 812px) {
  .row-column-alignment {
    display: flex;
    flex-direction: column;
  }

  .row-column-reverse-alignment {
    display: flex;
    flex-direction: column-reverse;
  }
}
