.hero-section-container {
  z-index: 1;
  margin-top: 20px;
}

.hero-section-sub-container {
  margin-top: 40px;
  /* background-color: red; */
}

.hero-section-title {
  font-size: 64px;
  font-weight: 500;
  color: var(--theme-opposite-color);
  line-height: 1.2;
  font-family: "anek-bangla", sans-serif !important;
}

.hero-section-title b {
  font-family: "anek-bangla", sans-serif !important;
  font-weight: inherit;
  color: var(--accent-color);
}

.hero-section-title i {
  font-family: "anek-bangla", sans-serif !important;
  font-style: normal;
  background: linear-gradient(39deg, white, #383838);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section-title i.reverse {
  background: linear-gradient(-39deg, white, #383838);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section-subtitle {
  font-size: 14px;
  line-height: 1.4;
  width: 90%;
  font-weight: 200;
  margin-top: 6px;
  margin-bottom: 40px;
  color: #ebebeb;
}

.hero-action-button-wrapper {
  align-self: flex-start;
  margin-top: -100px;
  margin-bottom: 100px;
}

.accent-button {
  background: var(--accent-color);
  border-radius: 6px;
  color: #212121;
  padding: 10px 18px;
  font-weight: 500;
}

.vertical-divider {
  width: 1px;
  height: 30px;
  margin: 0 12px;
  background-color: #ebebeb;
}

.on_desktop {
  display: flex;
}

.on_mobile {
  display: none;
}

@media screen and (max-width: 812px) {
  .hero-action-button-wrapper {
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .hero-section-title {
    font-size: 40px;
  }

  .hero-section-sub-container {
    margin-top: 10px;
    padding: 12px;
    /* background-color: red; */
  }

  .hero-section-subtitle {
    width: 100%;
  }

  .on_desktop {
    display: none;
  }

  .on_mobile {
    display: flex;
  }
}

@media screen and (max-width: 973px) {
  .hero-action-button-wrapper {
    margin-top: 0;
  }
}
