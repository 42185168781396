.telegram-app-title {
  font-size: 24px;
  font-weight: 400;
  color: var(--theme-opposite-color);
  line-height: 1.2;
  font-family: "anek-bangla", sans-serif;
  margin-bottom: 22px;
}

.telegram-app-title b {
  font-size: 34px;
  font-weight: inherit;
  color: var(--theme-opposite-color);
  font-family: "anek-bangla", sans-serif;
  background: linear-gradient(39deg, #ffffff, #242424);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.telegram-app-title i {
  font-style: normal;
  background: linear-gradient(39deg, white, #383838);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "anek-bangla", sans-serif;
}

/* .telegram-app-title i.reverse {
    background: linear-gradient(-39deg, white, #383838);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.telegram-apps-container img {
  height: 20px;
  width: 20px;
}

.telegram-apps-container > div {
  gap: 45px;
}

.telegram-apps-container {
  margin-bottom: 34px;
}

.telegram-app-locked-button {
  position: relative;
  padding: 24px 46px;
  color: var(--theme-opposite-color);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  z-index: 3;
}

.telegram-app-locked-button.locked {
  padding: 24px 30px;
  pointer-events: none; /* Disable click events */
  opacity: 0.6; /* Visually indicate that the div is disabled */
}

.telegram-app-locked-button > div {
  z-index: 3;
}

.telegram-app-locked-button:after {
  content: "";
  position: absolute;
  background: linear-gradient(84.72deg, #171717 7.76%, #272727 121.72%);
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 8px;
  z-index: 2;
}

.telegram-app-locked-button:before {
  content: "";
  position: absolute;
  background: linear-gradient(84.72deg, #171717 7.76%, #272727 121.72%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  z-index: 1;
  background: linear-gradient(87.6deg, #1d1d1d -0.56%, #3b3b3b 97.99%);
}

.coming-soon {
  color: var(--accent-color);
  font-size: 12px;
}

@media screen and (max-width: 812px) {
  .telegram-apps-container > div {
    gap: 16px;
  }
}
