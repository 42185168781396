/* navbar */
/* .nav-wrapper-container {
    background: var(--background);
    position: fixed;
    width: 100%;
    height: 110px;
} */

.nav-wrapper {
    background: var(--navbar-background-color);
    padding: 12px 30px;
    position: fixed;
    z-index: 999999;
    width: 90%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 18px 0;
    transition: 0.4s ease;
}

.nav-wrapper.full {
    width: 100%;
    margin: 0;
    border-radius: 0;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
}

.nav-logo {
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-right: 12px;
}

.header-name {
    color: var(--theme-opposite-color);
    font-size: 24px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.navbar-links {
    font-size: 15px;
    font-weight: 300;
    color: var(--theme-opposite-color);
    cursor: pointer;
}

.background-blur {
    backdrop-filter: blur(5px);
    height: 110px;
    width: 100%;
    opacity: 1;
    position: fixed;
    z-index: 99999;
    background: var(--background);
}







.get-alpha-access-container {
    max-width: 650px;
    width: 90%;
    padding-top: 200px;
}

.get-alpha-access-container>form>button {
    cursor: pointer;
    border: none;
    outline: none;
}

.email-field {
    padding: 14px;
    background: var(--theme-secondary-color);
    width: 100%;
    outline: none;
    border: none;
    border-radius: 8px;
    color: var(--theme-opposite-color);
    font-size: 14px;
    font-family: inherit;
}

.email-field::placeholder {
    color: var(--text-secondary-color);
    font-size: 14px;
    font-family: inherit;
}

.form-container {
    width: 60%;
}

.accent-color-container {
    border-radius: 8px;
    padding: 14px;
    width: 100%;
    background: var(--accent-color);
    color: var(--theme-color);
}

.white-small-text {
    color: var(--theme-opposite-color);
    font-size: 14px;
    font-weight: 400;
}

.white-medium-text {
    color: var(--theme-opposite-color);
    font-size: 18px;
    font-weight: 400;
}

.white-large-text {
    color: var(--theme-opposite-color);
    font-weight: 500;
    font-size: 30px;
}

.white-large-bold-text {
    color: var(--theme-opposite-color);
    font-weight: 700;
    font-size: 28px;
    font-family: "Montserrat", sans-serif;
}

.white-extra-large-text {
    color: var(--theme-opposite-color);
    font-size: 54px;
    font-weight: 800;
}

.white-extra-large-text2 {
    font-weight: 700;
    font-size: 40px;
    color: var(--theme-opposite-color);
    font-family: "Montserrat", sans-serif;
}

.black-medium-text {
    color: var(--theme-color);
    font-size: 18px;
    font-weight: 600;
}

.text-secondary-color-text {
    font-size: 16px;
    color: var(--text-secondary-color);
    font-weight: 500;
    font-family: "Montserrat", sans-serif;

}

/* .white-medium-bold-text{
    color: var(--theme-opposite-color);
    font-weight: 500;
    font-size: ;
} */



.welcome-to-vapourex-container {
    gap: 80px;
    padding: 80px 0;
}

.card-text-section-wrapper {
    gap: 40px;
}

.card-text-section-wrapper div {
    width: 50%;
}

.card-text-section-image-container>img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.card-text-section-text-container {
    gap: 20px;
}













.particles_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}



/* widget */
.widget-wrapper {
    max-width: 550px;
    width: 90%;
    background: var(--background-color);
    /* background: yellow; */
    margin: 54px 0;
    border-radius: 34px;
    padding: 10px;
    gap: 6px;
    position: relative;
    /* box-shadow: 0.5px 0.5px 8px 5px var(--theme-secondary-color); */
}

.widget-sub-wrapper {
    /* width: 100%; */
    background: var(--theme-secondary-color);
    border-radius: 24px;
    padding: 16px 24px;
    margin-bottom: 4px;
}

.widget-sub-wrapper:has(input:focus) {
    border: 1px solid #444444;
}

.coin-logo {
    height: 20px;
    width: 20px;
    object-fit: contain;
    object-position: center;

}

.black-color-bordered-wrapper {
    background: var(--background-color);
    border-radius: 32px;
    padding: 8px 16px;
    border: 1px solid #444444;
}

.coin-text {
    font-size: 24px;
    font-weight: 500;
    padding: 8px;
    font-family: "Inter", sans-serif !important;

}

.dropdown-container-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8989889;
}

.dropdown-container-wrapper>.dropdown-bg {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.dropdown-container {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: var(--background-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0;
    max-height: 90%;
    border: 1px solid var(--theme-secondary-color);
    border-radius: 24px;
    /* margin: 146px 0 24px 0; */
}

.dropdown-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    font-size: 24px;
    color: var(--theme-opposite-color);
    border-bottom: 1px solid var(--theme-secondary2-color);
    /* background-color: #f0f0f0; */
    /* border-radius: 4px; */
}

.dropdown-content {
    overflow: auto;
    flex: 1 1 auto;
}

.dropdown-content::-webkit-scrollbar {
    display: none;
}

.dropdown-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}



.dropdown-wrapper {
    border-radius: 22px;
}

.dropdown-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-content li {
    padding: 8px;
    cursor: pointer;
    color: var(--theme-opposite-color);
}

.dropdown-content li:hover {
    background-color: var(--theme-secondary-color);
}




/* Custom scrollbar */
.dropdown-content::-webkit-scrollbar {
    width: 8px;
}

.dropdown-content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Adjust scrollbar thumb color */
    border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Adjust scrollbar thumb hover color */
}

.dropdown-content::-webkit-scrollbar-track {
    background-color: #222;
    /* Adjust scrollbar track color */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.arrow_switch_icon {
    color: white;
    font-size: 38px;
    padding: 6px;
    align-self: center;
    margin: -24px;
    background: #000000;
    z-index: 1;
    border-radius: 12px;
    border: 4px solid #000000;
}














.navbar-margin {
    /* margin-top: 200px; */
}

.container-size {
    width: 80%;
    margin: auto;
    /* align-self: center; */
}

.join-meme-coin-revolution-section {
    padding: 80px 0;
}

.bottom-image-container {
    padding: 120px 0;
}

.bottom-image-container>img {
    width: 20%;
}


/* footer */
.footer-wrapper {
    width: 90%;
    padding: 38px;
    margin: auto;
}

.footer-header-name {
    color: var(--theme-opposite-color);
    font-size: 24px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.footer-sub-text{
    font-size: 14px;
    font-weight: 400;
}
.footer-heading{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
}
.footer-links{
    padding: 6px;
    font-size: 14px;
    font-weight: 400;
}

.footer-links-wrapper{
    width: 50%;
    justify-content: space-around;
}

@media screen and (max-width: 812px) {
    .footer-wrapper {
        width: 100%;
    }
    .footer-links-wrapper{
        width: 100%;
        justify-content: space-between;
    }
    .white-extra-large-text {
        font-size: 24px;
        font-weight: 600;
    }

    .white-large-text {
        font-weight: 400;
        font-size: 24px;
    }

    .header-name {
        display: none;
    }

    .navbar-margin {
        /* margin-top: 146px; */
    }


    .get-alpha-access-container {
        padding-top: 146px;
    }

    .container-size {
        width: 90%;
    }

    .white-large-bold-text {
        font-size: 22px;
    }

    .card-text-section-wrapper {
        gap: 16px;
    }

    .card-text-section-wrapper div {
        width: 100%;
    }

    .card-text-section-image-container>img {
        height: 160px;
    }

    .card-text-section-text-container {
        gap: 16px;
    }

    .welcome-to-vapourex-container {
        gap: 40px;
        padding: 40px 0;
    }

    .white-extra-large-text2 {
        font-weight: 700;
        font-size: 28px;
        color: var(--theme-opposite-color);
        font-family: "Montserrat", sans-serif;
    }

    .form-container {
        width: 80%;
    }

    .join-meme-coin-revolution-section {
        padding: 40px 0;
    }

    .bottom-image-container {
        padding: 20px 0 60px;
    }

    .footer-wrapper>span {
        font-size: 46px;
        font-weight: 700;
        color: var(--text-secondary-color)
    }

    .bottom-image-container>img {
        width: 50%;
    }

    /* .nav-wrapper {
        padding: 22px 22px;
    } */

    /* .background-blur {
        height: 84px;
    } */





}